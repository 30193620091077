import { FC, useEffect, useState } from "react"
import { Recipe } from "../Models/Recipe"
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd"
import { useForm } from "antd/es/form/Form"
import { IngredientPicker } from "./IngredientPicker"
import { IngredientMutable } from "../Models/Ingredient"
import TextArea from "antd/es/input/TextArea"
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons"
import { MealCategory } from "../Models/MealCategory"
import { RecipeCategory } from "../Models/RecipeCategory"
import { recipeCategoryService } from "../Services/RecipeCategory.service"
import { mealCategoryService } from "../Services/MealCategory.service"
import { hostedImage } from "../Helpers/Image.helper"
import { recipeService } from "../Services/Recipe.service"
import { bytesToKilobytes } from "../Helpers/File.helper"
import { debounce } from "lodash"

const { Text } = Typography

interface RecipeModalProps {
  recipe?: Recipe
  visible: boolean
  onCreate: (updatedRecipe: Recipe, options: any) => void
  onCancel: () => void
}

export const RecipeModal: FC<RecipeModalProps> = ({
  recipe,
  visible,
  onCreate,
  onCancel,
}) => {
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false)
  // Manual Form (intended)
  const [ingredients, setIngredients] = useState<IngredientMutable[]>([])
  const [draft, setDraft] = useState<boolean>(recipe?.draft ?? true)
  const [premium, setPremium] = useState<boolean>(recipe?.premium ?? false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [fireBackup, setFireBackup] = useState<number>(0)
  const [showFileSizeWarning, setShowFileSizeWarning] = useState<boolean>(false)
  const [backupAvailable, setBackupAvailable] = useState<boolean>(false)
  const [backupName, setBackupName] = useState<string>("")

  function handleIngredientsChange(ingredients: IngredientMutable[]) {
    setIngredients(ingredients)
    setIsDirty(true)

    if (ingredients.length > 0 && isDirty) {
      const stringifiedIngredients = JSON.stringify(ingredients)
      localStorage.setItem("ingredientsBackup", stringifiedIngredients)
    }
  }

  useEffect(() => {
    const handleIosBeforeUnload = (event: PageTransitionEvent) => {
      if (isDirty) {
        event.preventDefault()
        event.returnValue = false
      }
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
        event.returnValue = ""
      }
    }

    // If ios
    if (navigator.platform.match(/(iPhone|iPod|iPad)/i)) {
      window.addEventListener("pagehide", handleIosBeforeUnload)
    } else {
      window.addEventListener("beforeunload", handleBeforeUnload)
    }

    return () => {
      if (navigator.platform.match(/(iPhone|iPod|iPad)/i)) {
        window.removeEventListener("pagehide", handleIosBeforeUnload)
      } else {
        window.removeEventListener("beforeunload", handleBeforeUnload)
      }
    }
  }, [isDirty])

  useEffect(() => {
    // Trigger a new logging flow
    const stringifiedEditBackup = localStorage.getItem("editBackup") ?? ""
    const stringifiedCreateBackup = localStorage.getItem("createBackup") ?? ""
    const stringifiedIngredientsBackup =
      localStorage.getItem("ingredientsBackup") ?? ""

    if (
      stringifiedEditBackup.length > 0 ||
      stringifiedCreateBackup.length > 0 ||
      stringifiedIngredientsBackup.length > 0
    ) {
      setBackupAvailable(true)
      setBackupName(
        stringifiedEditBackup.length > 0
          ? JSON.parse(stringifiedEditBackup).title
          : stringifiedCreateBackup.length > 0
          ? JSON.parse(stringifiedCreateBackup).title
          : ""
      )
    }
  }, [])

  // Freezer
  const [freezerEnabled, setFreezerEnabled] = useState<boolean>(
    recipe?.storage.FREEZER.enabled ?? true
  )

  function hideFileSizeWarning() {
    setShowFileSizeWarning(false)
  }

  // Fridge
  const [fridgeEnabled, setFridgeEnabled] = useState<boolean>(
    recipe?.storage.FRIDGE.enabled ?? true
  )

  // CONTAINER
  const [containerEnabled, setContainerEnabled] = useState<boolean>(
    recipe?.storage.CONTAINER.enabled ?? false
  )

  // Form
  const [form] = useForm()
  const [mealCategories, setMealCategories] = useState<MealCategory[]>([])
  const [recipeCategories, setRecipeCategories] = useState<RecipeCategory[]>([])
  const [imageChanged, setImageChanged] = useState<any>({
    primaryImageURL: false,
    galleryImageURL: false,
    otherImageURLs: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      const fetchedRecipeCategories =
        await recipeCategoryService.getRecipeCategories()
      setRecipeCategories(fetchedRecipeCategories)

      const fetchedMealCategories =
        await mealCategoryService.getMealCategories()
      setMealCategories(fetchedMealCategories)
    }

    fetchData()
  }, [])

  function handleCancel(event?: any) {
    if (isDirty) {
      const _ = Modal.confirm({
        title:
          "Er du sikker på at du vil annullere og dermed ikke gemme ændringer?",
        content: "Du har ugemte ændringer",
        okText: "Ja, annuller ændringer",
        cancelText: "Gå tilbage",
        onOk: () => {
          setIsDirty(false)
          onCancel()
        },
      })
    } else {
      onCancel()
    }
  }

  // Block automatic upload
  async function handleBeforeUpload(key: string, event?: any) {
    const fileList: FileList | any = event?.fileList

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList?.length; i++) {
        const file: File | any = fileList[i]
        const filesize = bytesToKilobytes(file.size)

        if (filesize >= 10000) {
          setShowFileSizeWarning(true)
        }
      }
    }

    setImageChanged({ ...imageChanged, [key]: true })
    return false
  }

  // Submit form
  async function handleSubmit() {
    form.submit()
  }

  async function handleFinish(values: any) {
    setSaveDisabled(true)
    const newRecipe: any = !recipe
    const updatedRecipe = values

    const storageObject = {
      FRIDGE: {
        enabled: fridgeEnabled,
        min: values.storageFridgeFrom,
        max: values.storageFridgeTo,
        unit: values.storageFridgeUnit,
      },
      FREEZER: {
        enabled: freezerEnabled,
        min: values.storageFreezerFrom,
        max: values.storageFreezerTo,
        unit: values.storageFreezerUnit,
      },
      CONTAINER: {
        enabled: containerEnabled,
        min: values.storageContainerFrom,
        max: values.storageContainerTo,
        unit: values.storageContainerUnit,
      },
    }

    updatedRecipe.storage = storageObject

    values.draft = draft
    values.premium = premium

    // Clear local storage
    localStorage.removeItem("editBackup")
    localStorage.removeItem("createBackup")
    localStorage.removeItem("ingredientsBackup")

    await onCreate(values, { ingredients, imageChanged, newRecipe })

    // Clear form
    form.resetFields()

    setSaveDisabled(false)
  }

  // Handle checkbox tick
  function handleCheckboxTick(key: string, value: boolean) {
    if (key === "draft") {
      setDraft(value)
    } else if (key === "storageFreezerEnabled") {
      setFreezerEnabled(value)
    } else if (key === "storageFridgeEnabled") {
      setFridgeEnabled(value)
    } else if (key === "storageContainerEnabled") {
      setContainerEnabled(value)
    } else if (key === "premium") {
      setPremium(value)
    }
  }

  const handleFormChange = debounce((changedValues: any, allValues: any) => {
    const key = recipe ? "edit" : "create"

    const fields = form.getFieldsValue()
    // Return fields, but exclude all images
    const backupFields = Object.keys(fields)
      .filter((key) => !key.includes("ImageURL"))
      .reduce((obj: any, key) => {
        obj[key] = fields[key]
        return obj
      }, {})

    const stringifiedForm = JSON.stringify(backupFields)
    localStorage.setItem(`${key}Backup`, stringifiedForm)
  }, 1000)

  const defaulOtherString = ""

  const defaultPrimaryImageURL =
    (recipe?.primaryImageURL ?? "").length > 0
      ? [
          {
            url: hostedImage(recipe?.primaryImageURL ?? "", "primary"),
            uid: "primary1",
            name: "",
          },
        ]
      : []

  const defaultGalleryImageURL =
    (recipe?.galleryImageURL ?? "").length > 0
      ? [
          {
            url: hostedImage(recipe?.galleryImageURL ?? "", "gallery"),
            uid: "gallery2",
            name: "",
          },
        ]
      : []

  const defaultOtherImageURLs = recipe?.otherImageURLs?.map((url, index) => ({
    url: url,
    uid: index.toString(),
    name: "",
    realFileName: url,
  }))

  async function onDelete() {
    Modal.confirm({
      title: `Slet opskriften`,
      content: `Er du sikker på, at du vil slette denne opskrift? Dette kan ikke fortrydes.`,
      okText: "Ja, jeg er sikker",
      cancelText: "Nej",
      onOk: async () => {
        if (recipe?.id) {
          const success = await recipeService.deleteRecipe(recipe.id)

          if (success) {
            handleCancel()
          }
        }
      },
    })
  }

  const recoverFromBackup = () => {
    const stringifiedEditBackup = localStorage.getItem("editBackup") ?? ""
    const stringifiedCreateBackup = localStorage.getItem("createBackup") ?? ""

    if (
      stringifiedEditBackup.length === 0 &&
      stringifiedCreateBackup.length === 0
    ) {
      message.info("Der er ikke noget at gendanne")
      return
    }

    setFireBackup(fireBackup + 1)

    if (recipe && stringifiedEditBackup) {
      const recoveredValues = JSON.parse(stringifiedEditBackup)

      if (recoveredValues) {
        message.info("Gendanner eksisterende opskrift")
        form.setFieldsValue(recoveredValues)
      }
    } else if (!recipe && stringifiedCreateBackup) {
      const recoveredValues = JSON.parse(stringifiedCreateBackup)

      if (recoveredValues) {
        message.info("Gendanner ny opskrift")

        recoveredValues &&
          Object.keys(recoveredValues).map((key) => {
            const value = recoveredValues[key]

            if (value.length > 0) {
              form.setFieldsValue({ [key]: value })
            }
          })
      }
    } else {
      message.error("Det er ikke muligt at gendanne opskriften")
    }
  }

  // View body
  return (
    <>
      <Modal
        title={recipe ? "Rediger opskrift" : "Opret opskrift"}
        open={visible}
        onCancel={handleCancel}
        width={700}
        footer={[
          <Button onClick={onDelete} disabled={!recipe}>
            <DeleteOutlined style={{ color: "#f32e26" }} />
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Annuller
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            disabled={saveDisabled}
          >
            {recipe
              ? `Gem${draft ? " som kladde" : ""}`
              : `Opret ${draft ? "kladde" : "opskrift"}`}
          </Button>,
        ]}
      >
        {backupAvailable && backupName && (
          <Row style={{ marginBottom: 10 }}>
            <Popconfirm
              title={`Gendan "${backupName}"`}
              description="Dette overskriver alt. Er du sikker? (alle billeder forsvinder)"
              onConfirm={recoverFromBackup}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="dashed">
                {`Gendan "${backupName}"`}
              </Button>
            </Popconfirm>
          </Row>
        )}

        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={handleFinish}
          onFieldsChange={(changedValues, allValues) => {
            // add your additionaly logic here
            handleFormChange(changedValues, allValues)
            setIsDirty(true)
          }}
          initialValues={{
            id: recipe?.id,
            title: recipe?.title,
            quantity: recipe?.quantity,
            quantityType: recipe?.quantityType ?? "PEOPLE",
            catchphrase: recipe?.catchphrase,
            other: recipe?.other ?? defaulOtherString,
            description: recipe?.description,
            procedure: recipe?.procedure,
            categories: recipe?.categories?.map(
              (category: RecipeCategory) => category.id
            ),
            mealCategories: recipe?.mealCategories?.map(
              (category: MealCategory) => category.id
            ), //recipe?.mealCategory.id ?? mealCategories[0]?.id,
            minimumAge: recipe?.minimumAge ?? "6",
            draft: recipe?.draft,
            premium: recipe?.premium,

            // Fridge
            storageFridgeEnabled: recipe?.storage.FRIDGE.enabled ?? true,
            storageFridgeFrom: recipe?.storage.FRIDGE.min ?? 2,
            storageFridgeTo: recipe?.storage.FRIDGE.max ?? 3,
            storageFridgeUnit: recipe?.storage.FRIDGE.unit ?? "DAYS",

            // Freezer
            storageFreezerEnabled: recipe?.storage.FREEZER.enabled ?? true,
            storageFreezerFrom: recipe?.storage.FREEZER.min ?? 2,
            storageFreezerTo: recipe?.storage.FREEZER.max ?? 3,
            storageFreezerUnit: recipe?.storage.FREEZER.unit ?? "MONTHS",

            // Container
            storageContainerEnabled: recipe?.storage.CONTAINER.enabled,
            storageContainerFrom: recipe?.storage.CONTAINER.min,
            storageContainerTo: recipe?.storage.CONTAINER.max,
            storageContainerUnit: recipe?.storage.CONTAINER.unit ?? "DAYS",
            primaryImageURL: recipe?.primaryImageURL,
            galleryImageURL: recipe?.galleryImageURL,
            otherImageURLs: recipe?.otherImageURLs,
          }}
        >
          {/* Basic info */}
          <Row gutter={16}>
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Col span={12}>
              <Form.Item
                name="title"
                label="Titel"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="catchphrase" label="Catchphrase">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Beskrivelse"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Antal"
                name="quantity"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Input type="number" placeholder="Vælg antal" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Type" name="quantityType">
                <Select>
                  <Select.Option value="PEOPLE">Personer</Select.Option>
                  <Select.Option value="PIECES">Styk</Select.Option>
                  <Select.Option value="SLICES">Skiver</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* Details */}
          <h3>Detaljer</h3>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                name="categories"
                label="Opskriftskategori"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Select placeholder="Vælg opskriftskategori" mode="multiple">
                  {recipeCategories.map((recipeCategory) => (
                    <Select.Option
                      value={recipeCategory.id}
                      key={recipeCategory.id}
                    >
                      {recipeCategory.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="mealCategories"
                label="Måltidskategori"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Select placeholder="Vælg målkategori" mode="multiple">
                  {mealCategories.map((mealCategory) => (
                    <Select.Option
                      value={mealCategory.id}
                      key={mealCategory.id}
                    >
                      {mealCategory.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="minimumAge"
                label="Minimumsalder (mdr)"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Input type="number" placeholder="Indtast minimumsalder" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* Ingredients */}
          <h3>Ingredienser</h3>
          <Row gutter={16}>
            <Col span={24}>
              <IngredientPicker
                onChange={handleIngredientsChange}
                initial={recipe?.ingredients ?? []}
                fireBackup={fireBackup}
              />
            </Col>
          </Row>

          <Divider />

          {/* Storage */}
          <h3>Opbevaring</h3>
          <Row gutter={16} style={{ padding: 8 }}>
            {/* Fridge */}
            <Col xs={24} md={8}>
              <Row style={{ marginBottom: 8 }}>
                <Col span={24}>
                  <Form.Item name="storageFridgeEnabled">
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={fridgeEnabled}
                      onChange={(e) =>
                        handleCheckboxTick(
                          "storageFridgeEnabled",
                          e.target.checked
                        )
                      }
                    />
                    <Text>Aktiv</Text>
                  </Form.Item>
                  <Text>Køleskab</Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginRight: 8 }}>
                <Col span={6}>
                  <Form.Item name="storageFridgeFrom">
                    <Input type="text" placeholder="Fra" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="storageFridgeTo">
                    <Input type="text" placeholder="Til" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="storageFridgeUnit">
                    <Select placeholder="Enhed">
                      <Select.Option value="DAYS">Dage</Select.Option>
                      <Select.Option value="WEEKS">Uger</Select.Option>
                      <Select.Option value="MONTHS">Måneder</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Freezer */}
            <Col xs={24} md={8}>
              <Row style={{ marginBottom: 8 }}>
                <Col span={24}>
                  <Form.Item name="storageFreezerEnabled">
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={freezerEnabled}
                      onChange={(e) =>
                        handleCheckboxTick(
                          "storageFreezerEnabled",
                          e.target.checked
                        )
                      }
                    />
                    <Text>Aktiv</Text>
                  </Form.Item>
                  <Text>Fryser</Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginRight: 8 }}>
                <Col span={6}>
                  <Form.Item name="storageFreezerFrom">
                    <Input type="text" placeholder="Fra" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="storageFreezerTo">
                    <Input type="text" placeholder="Til" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="storageFreezerUnit">
                    <Select placeholder="Enhed">
                      <Select.Option value="DAYS">Dage</Select.Option>
                      <Select.Option value="WEEKS">Uger</Select.Option>
                      <Select.Option value="MONTHS">Måneder</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Container */}
            <Col xs={24} md={8}>
              <Row style={{ marginBottom: 8 }}>
                <Col span={24}>
                  <Form.Item name="storageContainerEnabled">
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={containerEnabled}
                      onChange={(e) =>
                        handleCheckboxTick(
                          "storageContainerEnabled",
                          e.target.checked
                        )
                      }
                    />
                    <Text>Aktiv</Text>
                  </Form.Item>
                  <Text>Beholder</Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginRight: 8 }}>
                <Col span={6}>
                  <Form.Item name="storageContainerFrom">
                    <Input type="text" placeholder="Fra" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="storageContainerTo">
                    <Input type="text" placeholder="Til" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="storageContainerUnit">
                    <Select placeholder="Enhed">
                      <Select.Option value="DAYS">Dage</Select.Option>
                      <Select.Option value="WEEKS">Uger</Select.Option>
                      <Select.Option value="MONTHS">Måneder</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          {/* Images */}
          <h3>Billeder</h3>
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Form.Item
                label="Opskriftsbillede"
                name="primaryImageURL"
                // rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <Upload
                  listType="picture"
                  maxCount={1}
                  beforeUpload={() => handleBeforeUpload("primaryImageURL")}
                  onChange={(e) => handleBeforeUpload("primaryImageURL", e)}
                  defaultFileList={defaultPrimaryImageURL}
                >
                  <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} md={{ span: 7, offset: 1 }}>
              <Form.Item label="Måltidsbillede" name="galleryImageURL">
                <Upload
                  listType="picture"
                  maxCount={1}
                  beforeUpload={() => handleBeforeUpload("galleryImageURL")}
                  onChange={(e) => handleBeforeUpload("galleryImageURL", e)}
                  defaultFileList={defaultGalleryImageURL}
                >
                  <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} md={{ span: 7, offset: 1 }}>
              <Form.Item label="Øvrige billeder" name="otherImageURLs">
                <Upload
                  listType="picture"
                  maxCount={4}
                  multiple
                  beforeUpload={() => handleBeforeUpload("otherImageURLs")}
                  onChange={(e) => handleBeforeUpload("otherImageURLs", e)}
                  defaultFileList={defaultOtherImageURLs}
                >
                  <Button icon={<UploadOutlined />}>Upload (Max: 4)</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* Images */}
          <h3>Tekst</h3>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Fremgangsmåde"
                name="procedure"
                rules={[{ required: true, message: "Feltet skal udfyldes" }]}
              >
                <TextArea rows={14} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Andet" name="other">
                <TextArea rows={14} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* Options */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="draft">
                <Checkbox
                  checked={draft}
                  onChange={(e) =>
                    handleCheckboxTick("draft", e.target.checked)
                  }
                />
                <Text style={{ marginLeft: 8 }}>Kladde</Text>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="premium">
                <Checkbox
                  checked={premium}
                  onChange={(e) =>
                    handleCheckboxTick("premium", e.target.checked)
                  }
                />
                <Text style={{ marginLeft: 8 }}>Kun for premium brugere</Text>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={showFileSizeWarning}
        onOk={hideFileSizeWarning}
        footer={[
          <Button key="ok" type="primary" onClick={hideFileSizeWarning}>
            Ok
          </Button>,
        ]}
      >
        <p>OBS: Billedet du har uploaded er for stort (større end 10mb).</p>
        <p>
          Der er derfor en chance for at det ikke vil vises efter opskriften er
          gemt.
        </p>
      </Modal>
    </>
  )
}
