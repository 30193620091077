import { api } from "./API.service"
import { Recipe } from "../Models/Recipe"
import { imageService } from "./Image.service"
import { message } from "antd"

class RecipeService {
  /**
   * Get recipes
   * @returns Promise containing an array of recipes
   */
  async getRecipes(): Promise<Recipe[]> {
    try {
      const response = await api.makeRequest("/admin/recipe?drafts=true")
      return response.content
    } catch (error) {
      console.error("Failed to fetch recipes:", error)
      throw error
    }
  }

  async getRecipe(id: string): Promise<Recipe> {
    try {
      const response = await api.makeRequest(`/admin/recipe/${id}`)

      return response.content
    } catch (error) {
      console.error("Failed to fetch recipe:", error)
      throw error
    }
  }

  /**
   * Create recipe
   * @param recipe The recipe to create
   * @returns Promise containing a boolean indicating the success of the creation
   */
  async createRecipe(recipe: Recipe, options?: any): Promise<boolean> {
    let primaryFileName: string = ""
    let galleryFileName: string = ""
    let otherImageNames: string[] = []

    try {
      // If the recipe has a primary image, upload it
      message.open({
        key: "updateRecipe",
        type: "loading",
        content: "Uploader og komprimerer billeder 🖼️",
        duration: 0,
      })

      if (
        options?.imageChanged?.primaryImageURL &&
        recipe.primaryImageURL.fileList.length > 0
      ) {
        const mediaSlug = await imageService.convertAndUploadImage(
          recipe.primaryImageURL.fileList[0].originFileObj,
          "primary"
        )

        if (mediaSlug) {
          primaryFileName = mediaSlug
        }
      }

      // If the recipe has a gallery image, upload it
      if (
        options?.imageChanged?.galleryImageURL &&
        recipe.galleryImageURL.fileList.length > 0
      ) {
        const mediaSlug = await imageService.convertAndUploadImage(
          recipe.galleryImageURL.fileList[0].originFileObj,
          "gallery"
        )

        if (mediaSlug) {
          galleryFileName = mediaSlug
        }
      }

      // If the recipe has other images
      if (options?.imageChanged?.otherImageURLs) {
        const otherImageUrls = await Promise.all(
          (recipe.otherImageURLs as any)?.fileList?.map(
            async (imageSource: any) => {
              // For some reason, this check doesn't work
              if (imageSource?.hasOwnProperty("realFileName")) {
                return imageSource.realFileName
              }

              const imageFile = imageSource.originFileObj
              const mediaSlug = await imageService.convertAndUploadImage(
                imageFile,
                "other"
              )

              return mediaSlug
            }
          )
        )

        otherImageNames = otherImageUrls
      }

      // Create the recipe
      recipe.primaryImageURL = options?.imageChanged?.primaryImageURL
        ? primaryFileName
        : recipe.primaryImageURL ?? ""

      recipe.galleryImageURL = options?.imageChanged?.galleryImageURL
        ? galleryFileName
        : recipe.galleryImageURL ?? ""

      recipe.otherImageURLs = options?.imageChanged?.otherImageURLs
        ? otherImageNames
        : recipe.otherImageURLs ?? []

      // Map ingredients to the correct format
      recipe.ingredients = options?.ingredients.map(
        (ingredient: any, index: number) => ({
          id: ingredient.id,
          amount: ingredient.amount,
          unit: ingredient.unit,
          order: index,
          optional: ingredient.optional,
        })
      )

      // Map storage to the correct format
      const storage = {
        FRIDGE: {
          enabled: recipe?.storage?.FRIDGE?.enabled ?? false,
          min: recipe?.storage?.FRIDGE.min ?? 0,
          max: recipe?.storage?.FRIDGE.max ?? 0,
          unit: recipe?.storage?.FRIDGE.unit ?? "DAYS",
        },
        FREEZER: {
          enabled: recipe?.storage?.FREEZER?.enabled ?? false,
          min: recipe?.storage?.FREEZER?.min ?? 0,
          max: recipe?.storage?.FREEZER?.max ?? 0,
          unit: recipe?.storage?.FREEZER?.unit ?? "DAYS",
        },
        CONTAINER: {
          enabled: recipe?.storage?.CONTAINER?.enabled ?? false,
          min: recipe?.storage?.CONTAINER?.min ?? 0,
          max: recipe?.storage?.CONTAINER?.max ?? 0,
          unit: recipe?.storage?.CONTAINER?.unit ?? "DAYS",
        },
      }

      // Set the storage
      recipe.storage = storage

      // Delete the old storage
      delete recipe.storageFridgeEnabled
      delete recipe.storageFridgeFrom
      delete recipe.storageFridgeTo
      delete recipe.storageFridgeUnit

      delete recipe.storageFreezerEnabled
      delete recipe.storageFreezerFrom
      delete recipe.storageFreezerTo
      delete recipe.storageFreezerUnit

      delete recipe.storageContainerEnabled
      delete recipe.storageContainerFrom
      delete recipe.storageContainerTo
      delete recipe.storageContainerUnit

      recipe.minimumAge = Number(recipe.minimumAge)
      recipe.quantity = Number(recipe.quantity)

      // If the recipe is new, create it
      if (options?.newRecipe) {
        await api.makeRequest("/admin/recipe", "POST", recipe)

        message.destroy()
        return true
      }

      if (!recipe?.id) {
        message.open({
          key: "updateRecipe",
          type: "error",
          content:
            "Der skete desværre en fejl under opdatering af opskriften 😢 [RS:#191]",
          duration: 5,
        })
        return false
      }

      /**
       * Update recipe
       */
      message.open({
        key: "updateRecipe",
        type: "loading",
        content: "Opdaterer opskrift 📝",
        duration: 0,
      })

      await api.makeRequest(`/admin/recipe/${recipe.id}`, "PUT", recipe)

      message.open({
        key: "updateRecipe",
        type: "success",
        content: "Opskriften er blevet opdateret 🎉",
        duration: 3,
      })

      return true
    } catch (error) {
      console.error("Failed to create recipe:", error)

      message.open({
        key: "updateRecipe",
        type: "error",
        content:
          "Der skete desværre en fejl under opdatering af opskriften 😢 [RS:#272]",
        duration: 10,
      })

      return false
    }
  }

  /**
   * Update recipe
   * @param recipe The updated recipe
   * @returns Promise containing a boolean indicating the success of the update
   */
  async updateRecipe(recipe: Recipe): Promise<boolean> {
    try {
      await api.makeRequest(`/admin/recipe/${recipe.id}`, "PATCH", recipe)
      return true
    } catch (error) {
      console.error("Failed to update recipe:", error)
      throw error
    }
  }

  /**
   * Delete recipe
   * @param recipeId ID of the recipe to delete
   * @returns Promise containing a boolean indicating the success of the deletion
   */
  async deleteRecipe(recipeId: number): Promise<boolean> {
    try {
      message.open({
        key: "deleteRecipe",
        type: "loading",
        content: "Sletter opskrift 🗑",
        duration: 0,
      })

      const success = await api.makeRequest(
        `/admin/recipe/${recipeId}`,
        "DELETE"
      )

      if (success) {
        message.open({
          key: "deleteRecipe",
          type: "success",
          content: "Opskriften er blevet slettet 🎉",
          duration: 3,
        })

        return true
      } else {
        message.open({
          key: "deleteRecipe",
          type: "error",
          content:
            "Der skete desværre en fejl under sletning af opskriften 😢 [RS:#412]",
          duration: 7,
        })

        return false
      }
    } catch (error) {
      console.error(`Failed to delete recipe with ID ${recipeId}:`, error)

      return false
    }
  }
}

export const recipeService = new RecipeService()
