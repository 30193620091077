import { FC, useEffect, useState } from "react"
import {
  Ingredient,
  IngredientCreation,
  IngredientMutable,
} from "../Models/Ingredient"
import { ingredientService } from "../Services/Ingredient.service"
import {
  AutoComplete,
  Col,
  Row,
  message,
  Typography,
  Button,
  Modal,
  Spin,
  List,
  Select,
  Tooltip,
  Checkbox,
} from "antd"
import Input from "antd/es/input/Input"
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ContainerOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import FlipMove from "react-flip-move"
import { IngredientUnit } from "../Models/IngredientUnit"
import { ingredientUnitService } from "../Services/IngredientUnit.service"
import { isMobile } from "../Helpers/Responsive.helper"

const { Text } = Typography

interface IngredientPickerProps {
  onChange: (ingredients: IngredientMutable[]) => void
  fireBackup: number
  initial: IngredientMutable[]
}
export const IngredientPicker: FC<IngredientPickerProps> = ({
  onChange,
  fireBackup,
  initial,
}) => {
  // Config
  const NEW_INGREDIENT_ID = -9999

  // State
  const [creatingIngredient, setCreatingIngredient] = useState<boolean>(false)
  const [ingredients, setIngredients] = useState<Ingredient[]>([])
  const [ingredientUnits, setIngredientUnits] = useState<IngredientUnit[]>([])
  const [ingredientQuery, setIngredientQuery] = useState<string>("")
  const [selectedIngredients, setSelectedIngredients] =
    useState<IngredientMutable[]>(initial)

  useEffect(() => {
    if (fireBackup > 0) {
      const stringifiedIngredients = localStorage.getItem("ingredientsBackup")
      const parsedIngredients = JSON.parse(stringifiedIngredients ?? "[]")

      setSelectedIngredients(parsedIngredients)

      const mappedIngredients = selectedIngredients.map((ingredient) => {
        // If unit is not a number
        if (typeof ingredient.unit !== "number") {
          ingredient.unit = ingredient?.unit?.id
        }

        return ingredient
      })

      onChange(mappedIngredients)
    }
  }, [fireBackup])

  useEffect(() => {
    const mappedIngredients = selectedIngredients.map((ingredient) => {
      // If unit is not a number
      if (typeof ingredient.unit !== "number") {
        ingredient.unit = ingredient?.unit?.id
      }

      return ingredient
    })

    onChange(mappedIngredients)
  }, [selectedIngredients])

  // Filter the ingredients
  function filterIngredients(): Ingredient[] {
    const ingredientsList = ingredients.filter((ingredient) =>
      ingredient.name.toLowerCase().includes(ingredientQuery.toLowerCase())
    )

    // Check if the ingredient query is empty
    if (ingredientsList.length === 0) {
      // Create a functional ingredient
      return [
        {
          id: NEW_INGREDIENT_ID,
          name: `Opret "${ingredientQuery}" som ingrediens`,
          visible: false,
          readOnly: true,
        },
      ]
    }

    // Return the ingredients
    return ingredientsList
  }

  // Filter the ingredients
  const filteredIngredients: Ingredient[] = filterIngredients()

  // Fetch the ingredients every time the page is loaded
  useEffect(() => {
    fetchIngredients()
    fetchIngredientUnits()
  }, [])

  /**
   * Fetch the ingredients from the ingredientService
   */
  async function fetchIngredients(): Promise<void> {
    try {
      const ingredients: Ingredient[] = await ingredientService.getIngredients()
      setIngredients(ingredients)
    } catch (error) {
      console.error("Error fetching ingredients")
    }
  }

  async function fetchIngredientUnits(): Promise<void> {
    try {
      const ingredientUnits: IngredientUnit[] =
        await ingredientUnitService.getIngredientUnits()
      setIngredientUnits(ingredientUnits)
    } catch (error) {
      console.error("Error fetching ingredient units")
    }
  }

  /**
   * Create an ingredient
   */
  async function createIngredient(name: string): Promise<number> {
    const newIngredient: IngredientCreation = {
      name: name,
      visible: true,
    }

    const success = await ingredientService.createIngredient(newIngredient)

    return success
  }

  /**
   * Handle ingredient selection
   * @param ingredientId
   */
  async function handleIngredientSelection(
    ingredientId: string | number
  ): Promise<void> {
    let chosenIngredient: Ingredient | undefined = ingredients.find(
      (ingredient) => Number(ingredient.id) === Number(ingredientId)
    )

    if (Number(ingredientId) === NEW_INGREDIENT_ID) {
      setCreatingIngredient(true)

      const newID = await createIngredient(ingredientQuery)
      await fetchIngredients()

      chosenIngredient = await ingredientService.getIngredient(newID)

      setCreatingIngredient(false)
    }

    // Check if an ingredient was chosen
    if (chosenIngredient) {
      // Check if the ingredient is already selected
      const ingredientIsSelected: boolean = selectedIngredients.some(
        (ingredient) => Number(ingredient.id) === Number(chosenIngredient?.id)
      )

      if (ingredientIsSelected) {
        return
      }

      const ingredientMutable: IngredientMutable = {
        name: chosenIngredient?.name ?? "",
        id: chosenIngredient?.id ?? 0,
        amount: 0,
        unit: 10001,
        order: selectedIngredients.length,
        optional: false,
      }

      setSelectedIngredients([...selectedIngredients, ingredientMutable])
      setIngredientQuery("")
    }
  }

  /**
   * Handle search
   * @param value
   */
  function handleSearch(value: string): void {
    setIngredientQuery(value)
  }

  /**
   * Remove ingredient from selected ingredients
   * @param ingredient
   */
  function removeIngredient(ingredient: IngredientMutable): void {
    const newSelectedIngredients: IngredientMutable[] =
      selectedIngredients.filter(
        (selectedIngredient) =>
          Number(selectedIngredient.id) !== Number(ingredient.id)
      )

    setSelectedIngredients(newSelectedIngredients)
  }

  /**
   * Reorder ingredients up or down
   * @param ingredient
   * @param direction
   */
  function reorderIngredient(
    ingredient: IngredientMutable,
    direction: "UP" | "DOWN"
  ): void {
    const newSelectedIngredients: IngredientMutable[] = [...selectedIngredients]

    // Find the index of the ingredient
    const ingredientIndex: number = newSelectedIngredients.findIndex(
      (selectedIngredient) =>
        Number(selectedIngredient.id) === Number(ingredient.id)
    )

    // Check if the ingredient is found
    if (ingredientIndex === -1) {
      return
    }

    // Check if the ingredient is already at the top
    if (ingredientIndex === 0 && direction === "UP") {
      message.info("Ingrediensen er allerede øverst")
      return
    }

    // Check if the ingredient is already at the bottom
    if (
      ingredientIndex === newSelectedIngredients.length - 1 &&
      direction === "DOWN"
    ) {
      message.info("Ingrediensen er allerede nederst")
      return
    }

    // Reorder the ingredients
    if (direction === "UP") {
      const tempIngredient: IngredientMutable =
        newSelectedIngredients[ingredientIndex - 1]
      newSelectedIngredients[ingredientIndex - 1] =
        newSelectedIngredients[ingredientIndex]
      newSelectedIngredients[ingredientIndex] = tempIngredient
    } else {
      const tempIngredient: IngredientMutable =
        newSelectedIngredients[ingredientIndex + 1]
      newSelectedIngredients[ingredientIndex + 1] =
        newSelectedIngredients[ingredientIndex]
      newSelectedIngredients[ingredientIndex] = tempIngredient
    }

    // Set the new selected ingredients
    setSelectedIngredients(newSelectedIngredients)
  }

  /**
   * Set the value of an ingredient property
   * @param ingredient
   * @param property
   * @param value
   * @returns
   */
  function setSpecificIngredientProperty(
    ingredient: IngredientMutable,
    property: string,
    value: any
  ) {
    const newSelectedIngredients: IngredientMutable[] = [
      ...selectedIngredients,
    ].map((selectedIngredient: IngredientMutable) => {
      if (Number(selectedIngredient.id) === Number(ingredient.id)) {
        selectedIngredient[property] = value
      }

      return selectedIngredient
    })

    setSelectedIngredients(newSelectedIngredients)
  }

  // View body
  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <AutoComplete
            disabled={creatingIngredient}
            style={{ width: "100%" }}
            placeholder="Søg efter ingrediens"
            value={ingredientQuery}
            onSearch={handleSearch}
            onSelect={handleIngredientSelection}
            options={filteredIngredients.map((ingredient) => ({
              value: ingredient.id,
              label: ingredient.name,
            }))}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <div>
            <FlipMove
              style={{
                borderRadius: 8,
                border: "1px solid #d9d9d9",
                padding: 8,
              }}
            >
              {selectedIngredients.length < 1 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 16,
                  }}
                >
                  <ContainerOutlined
                    style={{ fontSize: 48, marginBottom: 16, color: "#d9d9d9" }}
                  />
                  <Text>Der er ingen ingredienser tilknyttet endnu</Text>
                </div>
              ) : (
                selectedIngredients.map((ingredient, index) => {
                  const isLastItem: boolean =
                    index === selectedIngredients.length - 1

                  return (
                    <List.Item
                      key={ingredient.id}
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: 16,
                        paddingBottom: 16,
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderBottom: isLastItem ? "none" : "1px solid #d9d9d9",
                      }}
                    >
                      <Row gutter={8} style={{ width: "100%" }} align="middle">
                        <Col xs={24} md={{ flex: 1, span: 9 }}>
                          <Row>
                            <Text style={{ flexGrow: 1 }}>
                              <b>{ingredient.name}</b>
                            </Text>
                          </Row>
                          <Row>
                            <Checkbox
                              checked={ingredient.optional ?? false}
                              onChange={(e) =>
                                setSpecificIngredientProperty(
                                  ingredient,
                                  "optional",
                                  e.target.checked
                                )
                              }
                            />
                            <Text style={{ marginLeft: 8 }}>Valgfri</Text>
                          </Row>
                        </Col>

                        {isMobile && (
                          <Col xs={{ span: 24 }} md={{ span: 1, offset: 0 }}>
                            <br />
                          </Col>
                        )}

                        <Col>
                          <Input
                            placeholder="Mængde"
                            style={{ marginRight: "8px", width: 85 }}
                            value={ingredient.amount}
                            onKeyDown={(e) => {
                              if (e.key === ",") {
                                e.preventDefault()
                                e.stopPropagation()
                                message.error(
                                  "Brug punktum til at angive decimaler"
                                )
                                return false
                              }
                            }}
                            onChange={(e) =>
                              setSpecificIngredientProperty(
                                ingredient,
                                "amount",
                                e.target.value
                              )
                            }
                          />
                        </Col>

                        <Col>
                          <Select
                            placeholder="Enhed"
                            defaultValue={ingredient?.unit?.id ?? 10001}
                            onChange={(value) =>
                              setSpecificIngredientProperty(
                                ingredient,
                                "unit",
                                value
                              )
                            }
                            style={{
                              marginRight: "8px",
                              width: 85,
                            }}
                          >
                            {ingredientUnits?.map((unit) => (
                              <Select.Option key={unit.id} value={unit.id}>
                                {unit?.shortName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Col>

                        {isMobile && (
                          <Col xs={{ span: 24 }} md={{ span: 1, offset: 0 }}>
                            <br />
                          </Col>
                        )}

                        <Col>
                          <Tooltip title="Ryk op">
                            <Button
                              style={{ marginRight: "8px" }}
                              onClick={() =>
                                reorderIngredient(ingredient, "UP")
                              }
                            >
                              <ArrowUpOutlined />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Ryk ned">
                            <Button
                              onClick={() =>
                                reorderIngredient(ingredient, "DOWN")
                              }
                            >
                              <ArrowDownOutlined />
                            </Button>
                          </Tooltip>
                        </Col>

                        <Col>
                          <Tooltip title="Fjern">
                            <Button
                              onClick={() => removeIngredient(ingredient)}
                            >
                              <DeleteOutlined style={{ color: "#f32e26" }} />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    </List.Item>
                  )
                })
              )}
            </FlipMove>
          </div>
        </Col>
      </Row>

      <Modal
        open={creatingIngredient}
        footer={null}
        closable={false}
        width={400}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
          <Text
            style={{ marginTop: 16 }}
          >{`Opretter "${ingredientQuery}" som ingrediens.`}</Text>
          <Text>Vent venligst</Text>
        </div>
      </Modal>
    </>
  )
}
